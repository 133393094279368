<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-success cursor-pointer" @click="viewRecord"/>
    <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord"/>
    <feather-icon icon="NavigationIcon" svgClasses="h-5 w-5 mr-4 hover:text-success cursor-pointer" @click="listDeparture"/>
    <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord"/>
  </div>
</template>

<script>

import {mapActions} from 'vuex';
import loaderMixin from '../../../../mixins/loaderMixin';

export default {
  mixins: [loaderMixin],
  name: 'CellRendererActions',
  methods: {
    ...mapActions('main_package', [
      'deletePackageAction',
      'fetchAndSetPackages'
    ]),
    viewRecord() {
      this.$router.push("packages/" + this.params.data.id + "/view");
    },
    editRecord() {
      this.$router.push("packages/" + this.params.data.id + "/edit");
    },
    listDeparture() {
      this.$router.push("packages/" + this.params.data.id + "/departures");
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Delete`,
        text: `You are about to delete record with id: "${this.params.data.id}"`,
        accept: this.deleteRecord,
        acceptText: "Delete"
      })
    },
    deleteRecord() {
      this.openLoading();
      this.deletePackageAction(this.params.data.id).then(() => this.deletedSuccessfully());
    },
    deletedSuccessfully() {
      let params = new URLSearchParams();
      params.append("page", this.page);
      this.fetchAndSetPackages({query: params})
        .then(() => this.closeLoading()).catch((error) => this.closeLoading());

      this.$vs.notify({
        color: 'success',
        title: 'Package Deleted',
        position: 'top-right',
        text: 'The selected package was successfully deleted'
      })
    }
  }
}
</script>
